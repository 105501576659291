<template>
  <div>
    <section class="premium-offers">
      <section class="hero">
        <Card isReverse heading=" Go electric and unlock complimentary charging" headlineLevel="h1" :maxWidth="1200">
          <template #image>
            <picture
              ><source media="(min-width: 1200px)" srcset="@/assets/images/PremiumOffers/desert-charger.jpg" />
              <img
                class="image"
                src="@/assets/images/PremiumOffers/desert-charger.jpg"
                alt="This image displays an Electrify America charging station in the desert"
              /> </picture
          ></template>
          <p>Get complimentary charging when you purchase or lease an electric vehicle from a participating brand.*</p>
        </Card>
      </section>
      <section class="partners">
        <h2>Ride with the best</h2>
        <p>
          When you purchase an electric vehicle through a participating manufacturer, get
          <strong>complimentary Ultra-Fast charging or more membership benefits* </strong>at no additional cost.
        </p>
        <div class="partners__logo">
          <router-link :to="{ name: 'audi-en' }" aria-label="Click here to learn more about the Audi premium offer"
            ><img src="@/assets/images/PremiumOffers/audi-logo.webp" alt="Audi car logo"
          /></router-link>
          <router-link :to="{ name: 'bmw-en' }" aria-label="Click here to learn more about the BMW premium offer"
            ><img src="@/assets/images/PremiumOffers/bmw-logo.webp" alt="BMW car logo"
          /></router-link>
          <a
            href="https://www.ford.com/electric/public-ev-charging-near-me/?intcmp=bev-cta-publicEv"
            rel="noopener"
            target="_blank"
            aria-label="External link to Ford car opens in a new window"
          >
            <img src="@/assets/images/PremiumOffers/ford-logo.webp" alt="Ford car logo" />
          </a>
          <router-link
            :to="{ name: 'genesis-en' }"
            aria-label="Click here to learn more about the Genesis premium offer"
            ><img src="@/assets/images/PremiumOffers/genesis-logo.webp" alt="Genesis car logo"
          /></router-link>
          <router-link
            :to="{ name: 'hyundai-en' }"
            aria-label="Click here to learn more about the Hyundai premium offer"
            ><img src="@/assets/images/PremiumOffers/hyundai-logo.webp" alt="Hyundai car logo"
          /></router-link>
          <router-link :to="{ name: 'kia-en' }" aria-label="Click here to learn more about the Kia premium offer"
            ><img src="@/assets/images/PremiumOffers/kia-logo.webp" alt="kia Car logo" />
          </router-link>
          <a
            href="https://www.lucidmotors.com/charging"
            rel="noopener"
            target="_blank"
            aria-label="External link to Lucid Motors opens in a new window"
            ><img src="@/assets/images/PremiumOffers/lucid-motors-logo.webp" alt="Lucid motors car logo"
          /></a>
          <a
            rel="noopener"
            target="_blank"
            href="https://www.mbusa.com/en/charge/on-the-go"
            aria-label="External link to Mercedes car opens in a new window"
            ><img src="@/assets/images/PremiumOffers/mercedes-logo.webp" alt="Mercedes car logo"
          /></a>
          <a
            rel="noopener"
            target="_blank"
            href="https://www.porsche.com/usa/aboutporsche/e-performance/charging-bev/"
            aria-label="External link to Porsche car opens in a new window"
            ><img src="@/assets/images/PremiumOffers/porsche-logo.webp" alt="Porsche car logo"
          /></a>
          <router-link :to="{ name: 'vw-en' }" aria-label="Click here to learn more about the Volkswagen premium offer"
            ><img src="@/assets/images/PremiumOffers/volkswagen-logo.webp" alt="Volkswagen car logo"
          /></router-link>
        </div>
        <p><small>Brands are listed in alphabetical order.</small></p>
      </section>
      <section class="go-electric bg-gray">
        <h2 class="partners">Learn what it means to go electric</h2>
        <div class="l-three-col">
          <Card
            heading="Get familiar with the basics of EV charging"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #image>
              <img
                loading="lazy"
                src="@/assets/images/PremiumOffers/bulb.svg?external"
                alt="This image displays an Electrify America logo inside of a lightbulb."
              />
            </template>
            <p>
              Learn
              <router-link
                :to="{ name: 'what-to-expect-en' }"
                aria-label="Learn what to expect at the charger"
                class="link"
                >what to expect</router-link
              >
              at the charger and
              <router-link :to="{ name: 'charging-your-ev-en' }" class="link">how to charge</router-link>
            </p>
          </Card>
          <Card
            heading="Find Ultra-Fast charging stations near you"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #image>
              <img
                loading="lazy"
                src="@/assets/images/PremiumOffers/ea-bolt.svg?external"
                alt="This image displays an Electrify America logo inside of a location pin."
              />
            </template>
            <p>
              <router-link :to="{ name: 'locate-charger-en' }" class="link">Locate a charger</router-link> on the
              website and see real-time availability
              <router-link
                :to="{ name: 'mobile-app-en' }"
                class="link"
                aria-label="See charger availability in real time in the app"
                >in the app</router-link
              >
            </p>
          </Card>
          <Card
            heading="Enroll in your Premium Offer with the app"
            headlineLevel="h3"
            isSmall
            :isReverse="isPhone"
            headlineDisplayClass="headline2"
          >
            <template #reference>
              <reference-link :num="1">
                Your mobile carrier's messaging, data, and other rates and fees will apply to your use of the Electrify
                America mobile application.
              </reference-link>
            </template>

            <template #image>
              <img
                loading="lazy"
                src="@/assets/images/PremiumOffers/electric-bolt.svg?external"
                alt="This image displays an Electrify America log inside of a circle."
              />
            </template>
            <p>
              Learn how to
              <router-link :to="{ name: 'mobile-app-en' }" class="link">create an account</router-link> and use your
              complimentary charging*
            </p>
          </Card>
        </div>
      </section>
      <section class="partners app">
        <h2>Use the Electrify America app to enroll and save</h2>
        <p>
          If you own or lease a vehicle with a Premium Offer from Genesis, Hyundai, Kia, or Volkswagen use the Electrify
          America app to enroll.
          <reference-link :num="1">
            Your mobile carrier's messaging, data, and other rates and fees will apply to your use of the Electrify
            America mobile application.
          </reference-link>
        </p>
        <div class="app__logo">
          <div>
            <img src="@/assets/images/PremiumOffers/genesis-logo.webp" alt="Genesis car logo" />
            <strong v-if="!isMobile">Genesis</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/hyundai-logo.webp" alt="Hyundai car logo" />
            <strong v-if="!isMobile">Hyundai</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/kia-logo.webp" alt="Kia car logo" />
            <strong v-if="!isMobile">Kia</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/volkswagen-logo.webp" alt="Volkswagen car logo" />
            <strong v-if="!isMobile">Volkswagen</strong>
          </div>
        </div>
      </section>
      <section class="redeem-charging">
        <Card
          headlineLevel="h2"
          heading="Use an enrollment code to redeem your complimentary charging"
          headlineDisplayClass="headline2"
          isReverse
          :maxWidth="1100"
        >
          <template #image>
            <picture>
              <img
                loading="lazy"
                class="image"
                src="@/assets/images/PremiumOffers/mobile.webp"
                alt="This image displays the enrollment process for a Premium Offer in the Electrify American app on an iPhone."
              />
            </picture>
          </template>
          <p>
            <span class="redeem-charging__number"><strong>1</strong></span> Download the Electrify America
            app<reference-link :num="1">
              Your mobile carrier's messaging, data, and other rates and fees will apply to your use of the Electrify
              America mobile application
            </reference-link>
            and create your account
          </p>
          <p>
            <span class="redeem-charging__number"><strong>2</strong></span> Select <strong>Premium Offer</strong> in the
            Account tab
          </p>
          <p>
            <span class="redeem-charging__number"><strong>3</strong></span> Tap<strong>
              I have an enrollment code</strong
            >
          </p>
          <p>
            <span class="redeem-charging__number"><strong>4</strong></span> Enter your enrollment code and select your
            plan
          </p>
          <p class="flex-display">
            <span class="redeem-charging__number"><strong>5</strong></span>
            Accept the Plan Disclosure and add a backup payment method for any fees that are not covered by your
            complimentary charging**
          </p>
          <p class="redeem-charging__mr">
            <strong>Note: </strong>Some automakers have their own mobile app to enroll in complimentary charging. The
            following section provides more information.
          </p>
        </Card>
      </section>
      <section class="ea-benefit bg-gray">
        <h2>Use your vehicle’s app to enroll</h2>
        <p>
          If you own or lease a vehicle with complimentary charging from any of these brands, use their app to enroll.
        </p>
        <div class="ea-benefit__logo">
          <div>
            <img src="@/assets/images/PremiumOffers/audi.webp" alt="Audi car logo" /><strong v-if="!isMobile"
              >Audi</strong
            >
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/bmw.webp" alt="BMW car logo" />
            <strong v-if="!isMobile">BMW</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/ford.webp" alt="Ford car logo" />
            <strong v-if="!isMobile">Ford</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/lucid.webp" alt="Lucid motors car logo" />
            <strong v-if="!isMobile">Lucid</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/mercedes.webp" alt="Mercedes car logo" />
            <strong v-if="!isMobile">Mercedes</strong>
          </div>
          <div>
            <img src="@/assets/images/PremiumOffers/porsche.webp" alt="Porsche car logo" />
            <strong v-if="!isMobile">Porsche</strong>
          </div>
        </div>
        <p><small>Brands are listed in alphabetical order.</small></p>
      </section>
      <section class="pass-member">
        <Card
          headlineLevel="h2"
          heading="Become a Pass+ Member"
          :isReverse="true"
          headlineDisplayClass="headline3"
          class="no-bottom-padding"
          :maxWidth="1100"
        >
          <template #image>
            <picture>
              <source media="(min-width:750px)" srcset="@/assets/images/PremiumOffers/station.webp" />
              <source srcset="@/assets/images/PremiumOffers/station-mobile.webp" />
              <img
                loading="lazy"
                class="image"
                src="@/assets/images/PremiumOffers/station.webp"
                alt="This image displays a person holding their phone up to an NFC reader on an Electrify America charger."
              />
            </picture>
          </template>
          <p>Enjoy reduced costs on charging with the nation's largest public fast charging network.</p>
          <router-link class="button-link" :to="{ name: 'mobile-app-en' }"
            >Learn more about membership options<arrow class="arrow"></arrow
          ></router-link>
        </Card>
      </section>
      <section class="partners disclaimer">
        <p>
          <small>
            *Program details vary, depending on the vehicle model and manufacturer and date of purchase. See the
            promotion terms and conditions for your specific vehicle for full information and restrictions, available at
            <router-link class="link" :to="{ name: 'audi-en' }">Audi</router-link>,
            <router-link class="link" :to="{ name: 'bmw-en' }">BMW</router-link>,
            <a
              href="https://www.ford.com/electric/public-ev-charging-near-me/?intcmp=bev-cta-publicEv"
              rel="noopener"
              target="_blank"
              class="link"
              >Ford</a
            >, <router-link class="link" :to="{ name: 'genesis-en' }">Genesis</router-link>,
            <router-link class="link" :to="{ name: 'hyundai-en' }">Hyundai</router-link>,
            <router-link class="link" :to="{ name: 'kia-en' }">Kia</router-link>,
            <a href="https://www.lucidmotors.com/charging" rel="noopener" target="_blank" class="link">Lucid</a>,
            <a href="https://www.mbusa.com/en/charge/on-the-go" rel="noopener" target="_blank" class="link">Mercedes</a
            >,
            <a
              href="https://www.porsche.com/usa/aboutporsche/e-performance/"
              rel="noopener"
              target="_blank"
              class="link"
              >Porsche</a
            >, and <router-link class="link" :to="{ name: 'VW-en' }">Volkswagen</router-link>.
          </small>
        </p>
        <p>
          <small>
            **To help ensure you can charge uninterrupted, your Electrify America account balance automatically reloads
            when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the first time you
            charge at an Electrify America station, and the billed amount will be held in your account to cover costs
            not covered by this plan, such as idle fees and applicable taxes. Drivers who do not unplug and move their
            vehicle ten minutes after their charging session is complete will be charged a $0.40 per-minute idle fee.
            This fee encourages drivers to move their car promptly so that others can use the charger. You can be
            notified when your charging session is complete by text message (your mobile carrier’s messaging, data and
            other rates may apply) or see it on the charger screen.</small
          >
        </p>
      </section>
    </section>
  </div>
</template>

<script>
import Card from '@/components/Card/Card.vue';
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';

import arrow from '@/assets/images/Global/arrow.svg';

export default {
  components: { arrow, Card, ReferenceLink },
  metaInfo: {
    title: 'Electrify America | Premium Offers',
    meta: [
      {
        name: 'description',
        content:
          'Get complimentary charging with Electrify America when you purchase or lease an electric vehicle from a participating brand',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/premium-offers/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
.premium-offers {
  margin-bottom: 64px;
  ::v-deep .card img {
    @media (max-width: $bp_medium_min) {
      margin-top: 20px;
    }

    @media (min-width: $bp_medium_min) {
      max-width: 482px;
    }
  }
  .hero {
    width: 100%;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(218, 239, 223, 1) 100%);
    .image {
      border-radius: 10px;
    }
  }
  .partners {
    margin: 30px auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    p {
      max-width: 600px;
      text-align: center;
    }
    &__logo {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      @media (max-width: $bp_medium_min) {
        grid-template-columns: repeat(2, 1fr);
        margin: 20px;
      }
      gap: 20px 60px;
      margin: 30px;
      img {
        transition: transform 0.2s;
        max-width: 120px;
        @media (max-width: $bp_medium_min) {
          max-width: 80px;
        }
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .app {
    &__logo {
      display: flex;
      margin-top: 40px;
      margin: 0 20px;
      img {
        width: 60px;
        margin-right: 10px;
      }
      div {
        display: flex;
        align-items: center;
        max-width: 150px;
      }
      div:not(:last-child) {
        margin-right: 80px;
        @media (max-width: $bp_medium_min) {
          margin-right: 20px;
        }
      }
    }
  }
  .bg-gray {
    background: $c-secondary-background;
    padding: 40px 0;
  }
  .go-electric {
    padding-bottom: 80px;
    margin-bottom: 80px;
    h2 {
      margin-bottom: 60px;
    }
    ::v-deep .card {
      border: 1px solid #d7dcf3;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      background: $c-primary-background;
      max-width: 384px;
      .card__container {
        justify-content: start;
        align-items: start;
      }
    }
    .l-three-col {
      justify-content: space-between;
    }
  }
  .disclaimer {
    p {
      text-align: justify;
      padding: 20px;
    }
    p:last-child {
      margin-top: 0px;
    }
  }

  .redeem-charging {
    .flex-display {
      display: flex;
    }
    ::v-deep .card {
      h2 {
        font-weight: $fw-light;
      }
      img {
        max-height: 560px;
      }
      .card__column {
        @media (min-width: $bp_small_min) {
          max-width: 60%;
        }
        margin-right: 0px;
      }
      .card__image {
        padding: 0 70px;
        // background: radial-gradient(0% red, 100% green);
        background-image: radial-gradient(
          rgba(218, 239, 223, 1) 50%,
          rgba(255, 255, 255, 1) 70%,
          $c-primary-background 100%
        );
      }
    }
    &__number {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: $c-primary;
      color: $c-primary-background;
      text-align: center;
      padding: 1.5% 2%;
      margin-right: 10px;
    }
    &__mr {
      margin-left: 45px !important;
    }
  }
  .ea-benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    text-align: center;
    &__logo {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: $bp_medium_min) {
        grid-template-columns: repeat(2, 1fr);
        margin: 20px;
      }
      gap: 20px 60px;
      margin: 30px;
      img {
        width: 60px;
      }
      div {
        display: flex;
        align-items: center;
        max-width: 300px;
      }
      div:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
</style>
